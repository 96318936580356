import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Ar from "./Ar";
import En from "./En";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/Ar" element={<Ar />} />
        <Route path="/En" element={<En />} />
        <Route path="/" element={<Ar />} />
      </Routes>
    </>
  );
};

export default App;
