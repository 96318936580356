import email from "./email.png";
import call from "./call.png";

function Contact_Ar() {
  return (
    <div id="contact" className="container mx-auto p-4 md:p-16 mt-24">
      <div className="bg-[#160045] text-white rounded-md p-4">
        <h1 className="text-3xl text-right border-b-2 border-[#6d6784] p-4">
          للتواصل
        </h1>
        <div className="p-2 md:p-6 flex mt-4 justify-end">
          <div>sales@hashtag.om</div>
          <img className="ml-2" src={email} alt="Email Icon" />
        </div>
        <div className="p-2 md:p-6 flex justify-end">
          <div>555555555</div>
          <img className="ml-2" src={call} alt="Email Icon" />
        </div>
      </div>
    </div>
  );
}

export default Contact_Ar;
