import React, { useEffect } from "react";
import "./App.css";
import Cards from "./cards";
import Landing from "./landing";
import Talk from "./talk";
import Footer from "./footer";
import Contact from "./contact";
import { Route, Routes } from "react-router-dom";

const En = () => {
  return (
    <>
      <Landing />
      <Cards />
      <Talk />
      <Contact />
      <Footer />
    </>
  );
};

export default En;
