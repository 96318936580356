function Cards_Ar() {
  return (
    <div id="services" className="container mx-auto p-4 md:p-12 mt-8">
      <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 ">
        <div className="col-span-1 order-1 md:order-3 md:col-span-1 lg:col-span-1">
          <div className="text-white text-3xl mb-6 text-right">
            متخصصي السوق العماني
          </div>
          <div className="text-white px-4 md:px-0 text-right text-lg">
            نحن في هاشتاغ، نتبنى نهجًا فريدًا. بدون تكلفة مقدمة، تفانٍ في نجاحك.
            اكتشف عالم التسويق الرقمي الذي يعتمد على النتائج، صُنع بشغف .وتقديم
            بتميز. أهدافك، هي التزامنا
          </div>
        </div>
        <div className="col-span-1 order-1 bg-[#160045] text-white rounded-md mx-4 ">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4 text-right">
            الاعلانات المدفوعة
          </h1>
          <div className="p-6 text-right">
            ندير إعلانات موجهة بشكل دقيق باستخدام احدث التقنيات لضمان حصولك على
            أفضل عائد على الاستثمار علي افضل النتائج. اعتمادًا على جمهورك
            المستهدف وطبيعة خدماتك، سنحدد نوع الإعلان الأكثر ملاءمة وأفضل السائط
            .لبثها عليها
          </div>
        </div>
        <div className="col-span-1 order-2 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4 text-right">
            تسويق وسائل التواصل الاجتماعي
          </h1>
          <div className="p-6 text-right">
            إذا لم تكن على منصات مثل فيسبوك وإنستجرام ولينكد إن، فأنت تفوت
            الكثير! يمكن أن يحقق التسويق الرائع على وسائل التواصل الاجتماعي
            نجاحًا ملحوظًا لعملك، حيث يمكن أن يخلق مؤيدين مخلصين للعلامة
            .التجارية و يحقق زيادة في عدد العملاء والمبيعات
          </div>
        </div>

        <div className="col-span-1 order-4 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4 text-right">
            تحرير محتوي اعلاني
          </h1>
          <div className="p-6 text-right">
            نقوم بكتابة نصوص تسويقية قوية تحفز الأفراد على اتخاذ إجراء، مثل
            عملية شراء، أو النقر علي رابط، أو التبرع , أو تحديد موعد .لاستشارة،
            سواء للجمهور العربي أو الإنجليزي
          </div>
        </div>
        <div className="col-span-1 order-5 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4 text-right">
            اللوحات الإعلانية
          </h1>
          <div className="p-6 text-right">
            تخيل - علامتك التجارية تتسامى فوق البقية، تجذب انتباه كل من يمر بها،
            تترك علامة لا تنسى في عقول جمهورك المستهدف. نحن رواة قصص قويون، نحكي
            قصة علامتك التجارية على وجه .اللوحة الحضرية الواسعة
          </div>
        </div>
        <div className="col-span-1 order-6 md:col-end-4 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4 text-right">
            المتاجر الإلكترونية
          </h1>
          <div className="p-6 text-right">
            أصبح بيع المنتجات والخدمات عبر الإنترنت جزءًا أساسيًا من التجارة
            الحديثة. هذا التحول هو هنا ليبقى، مما يقدم فرصًا لأصحاب الأعمال
            للازدهار عبر الإنترنت. نحن نساعدك في إعداد متجرك على الإنترنت وفهم
            .كيفية عمله بشكل كامل
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards_Ar;
