import email from "./email.png";

function Contact() {
  return (
    <div id="contact" className="container mx-auto p-4 md:p-16 mt-24">
      <div className="bg-[#160045] text-white rounded-md p-4">
        <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">Contact Us</h1>
        <div className="p-2 md:p-6 flex mt-4">
          <img className="mr-2" src={email} />
          <div>sales@hashtag.om</div>
        </div>
        <div className="p-2 md:p-6 flex">
          <img className="mr-2" src={email} />
          <div>555555555</div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
