function Talk() {
  return (
    <div
      id="talk"
      className="container mx-auto flex flex-col items-center mt-28 border-t-[1px] border-[#6d6784] pt-20 px-2"
    >
      <div className="text-3xl text-[#6d6784] text-center max-w-24 mb-8">
        We only charge after delivering results,
        <br /> ensuring your satisfaction and success
      </div>
      <div className="bg-[#6100ff] shrink-0 grow-0 text-white text-lg rounded-full flex items-center px-20 py-32 -mt-12 ">
        THAT'S RIGHT!
      </div>
    </div>
  );
}

export default Talk;
