import logo from "./logo.png";
import { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
function Landing_Ar() {
  return (
    <div className="container mx-auto h-screen pl-6 pr-6 md:pl-24 md:pr-24 realative">
      <div className="flex flex-row-reverse	 items-center justify-center gap-6 p-4 mt-6 text-sm md:text-lg text-white bg-[#3c345b] bg-opacity-50 rounded-3xl">
        <div className="md:mr-8 col-start hidden md:block">
          <img className="md:w-32 bg-opacity-50" src={logo} alt="Logo" />
        </div>
        <div className="hover:text-[#0b0132] cursor-pointer">
          <ScrollLink to="services" smooth={true} duration={800} offset={-35}>
            خدماتنا
          </ScrollLink>
        </div>
        <div className="hover:text-[#0b0132] cursor-pointer">
          <ScrollLink to="talk" smooth={true} duration={800} offset={-35}>
            ثقافتنا
          </ScrollLink>
        </div>
        <div className="hover:text-[#0b0132] cursor-pointer">
          <ScrollLink to="contact" smooth={true} duration={800} offset={-35}>
            تواصل
          </ScrollLink>
        </div>
        <div className="text-sm hover:text-[#0b0132] cursor-pointer border-r-2 pr-2">
          <Link to="/En">En</Link>
        </div>
      </div>
      <div className="md:mr-8 col-start">
        <img
          className="w-24 md:w-32 mx-auto bg-opacity-50 md:hidden"
          src={logo}
          alt="Logo"
        />
      </div>
      <h1 className="text-2xl md:text-5xl text-center text-white md:mt-28 max-w-xl mx-auto">
        تميز بتطوير علامتك التجارية وزيادة مبيعاتك{" "}
      </h1>
      <div className="text-sm md:text-lg text-center font-bold text-white mt-8 max-w-lg mx-auto">
        نحن أكثر من مجرد وكالة تسويق رقمي. نحن فريق ملهم من الخبراء والمبدعين
        الذين يؤمنون بقوة التسويق الرقمي في بناء العلامات التجارية وتحقيق النجاح
        .على الإنترنت
      </div>
      <div className="flex items-center justify-center mt-12">
        <button className="bg-white text-[#6100ff] py-2 px-4 rounded-full p-4">
          <ScrollLink to="contact" smooth={true} duration={800} offset={-35}>
            تواصل معنا
          </ScrollLink>
        </button>
      </div>{" "}
      <video
        className="absolute inset-0 w-full h-full object-cover z-[-1]"
        id="waving-video"
        autoPlay
        loop
        muted
        playsInline
        style={{
          backgroundImage:
            "url('https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-poster-00001.jpg')",
        }}
      >
        <source
          src="https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-transcode.mp4"
          type="video/mp4"
        />
        <source
          src="https://assets-global.website-files.com/64383d8e5c3c91d9b995b8b7/64425178167de08b8f05b15a_banner%20video-transcode.webm"
          type="video/webm"
        />
      </video>
      <div className="banner-video-overlay"></div>
    </div>
  );
}

export default Landing_Ar;
