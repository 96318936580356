import insta from "./inst.png";
import x from "./x.png";
import facebook from "./facebook.png";

function Footer() {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-32 mb-4 px-4">
      <div className="flex items-center justify-center gap-4 mb-2">
        <img className="w-8 h-8" src={insta} alt="Instagram" />
        <img className="w-8 h-8" src={facebook} alt="Facebook" />
        <img className="w-8 h-8" src={x} alt="X" />
      </div>
      <div className="text-white text-center mb-4">
        All Rights Reserved 2023. Hashtag©
      </div>
    </div>
  );
}

export default Footer;
