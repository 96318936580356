function Cards() {
  return (
    <div id="services" className="container mx-auto p-4 md:p-12 mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="col-span-1 md:col-span-2">
          <div className="text-white text-xl mb-6 ">
            Oman #1 Marketing Agency{" "}
          </div>
          <div className="text-white text-left px-4 md:px-0">
            we at Hashtag, Embrace a Unique Approach. No Upfront Costs, Just
            Pure Dedication to Your Success. Discover a World of Results-Driven
            Digital Marketing, Crafted with Passion and Delivered with
            Excellence. Your Goals, is Our Commitment.
          </div>
        </div>
        <div className="bg-[#160045] text-white rounded-md mx-4 ">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">
            Paid Advertisments
          </h1>
          <div className="p-6">
            We run laser-focused Ads to ensure you get the best ROI (return on
            investment) possible. Depending on your target audience and the
            nature of your services, we will identify the most suitable ad type
            and the best media platforms to broadcast them on.
          </div>
        </div>
        <div className="bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">
            Social Media Marketing
          </h1>
          <div className="p-6">
            if you’re not on platforms like Facebook, Instagram, and LinkedIn,
            you’re missing out! Great marketing on social media can bring
            remarkable success to your business, creating devoted brand
            advocates and even driving leads and sales.
          </div>
        </div>
        <div className="col-span-1 md:col-end-4 lg:col-end-4 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">
            Arabic/English Copywritting
          </h1>
          <div className="p-6">
            We write call-to-action, persuasive marketing and promotional
            materials that motivate people to take action, such as make a
            purchase, click on a link, donate to a cause, or schedule a
            consultation, both for Arabic and English audience.
          </div>
        </div>
        <div className="col-span-1 md:col-end-4 lg:col-end-5 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">
            Billboard Ads
          </h1>
          <div className="p-6">
            Picture this – your brand towering above the rest, commanding
            attention from every passerby, leaving an indelible mark on the
            minds of your target audience. a powerful storytellers, narrating
            your brand's narrative against the vast canvas of the urban jungle.
          </div>
        </div>
        <div className="col-span-1 md:col-end-4 lg:col-end-5 bg-[#160045] text-white rounded-md mx-4">
          <h1 className="text-3xl border-b-2 border-[#6d6784] p-4">E-Stores</h1>
          <div className="p-6">
            Selling products and services over the internet has become a staple
            of modern commerce. That shift is here to stay, presenting
            opportunities for business owners to thrive online. we help you set
            up your internet shop and understand how it all works.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
