import React, { useEffect } from "react";
import "./App.css";
import Footer from "./footer";
import Landing_Ar from "./landing-AR";
import Cards_Ar from "./Cards-Ar";
import Talk_Ar from "./talk-Ar";
import Contact_Ar from "./contact-Ar";
import { Route, Routes } from "react-router-dom";

const Ar = () => {
  return (
    <>
      <Landing_Ar />
      <Cards_Ar />
      <Talk_Ar />
      <Contact_Ar />
      <Footer />
    </>
  );
};

export default Ar;
