function Talk_Ar() {
  return (
    <div
      id="talk"
      className="container mx-auto flex flex-col items-center mt-28 border-t-[1px] border-[#6d6784] pt-20 px-2"
    >
      <div className="text-3xl text-[#6d6784] text-center max-w-24 mb-8">
        نفرض الرسوم فقط بعد تحقيق النتائج
        <br /> .لضمان رضاك ونجاحك
      </div>
      <div className="bg-[#6100ff] shrink-0 grow-0 text-white text-xl rounded-full flex items-center px-24 py-28 -mt-12 ">
        !بدون مقدم
      </div>
    </div>
  );
}

export default Talk_Ar;
